exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-carpentry-js": () => import("./../../../src/pages/carpentry.js" /* webpackChunkName: "component---src-pages-carpentry-js" */),
  "component---src-pages-clean-ups-js": () => import("./../../../src/pages/clean-ups.js" /* webpackChunkName: "component---src-pages-clean-ups-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-general-handyman-js": () => import("./../../../src/pages/general-handyman.js" /* webpackChunkName: "component---src-pages-general-handyman-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kitchen-bath-js": () => import("./../../../src/pages/kitchen-bath.js" /* webpackChunkName: "component---src-pages-kitchen-bath-js" */),
  "component---src-pages-painting-js": () => import("./../../../src/pages/painting.js" /* webpackChunkName: "component---src-pages-painting-js" */),
  "component---src-pages-tile-js": () => import("./../../../src/pages/tile.js" /* webpackChunkName: "component---src-pages-tile-js" */)
}

